import React, { useState } from "react";
import {
  useRecordContext,
  useDataProvider,
  useNotify,
  Button,
  AutocompleteInput,
  ReferenceInput,
} from "react-admin";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Paper,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";

type FilterParams = {
  title?: string;
  classId?: string;
};

export const RequiredClasses = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [selectedClassId, setSelectedClassId] = useState<string>("");

  const refetchAchievement = async () => {
    try {
      await dataProvider.getOne("achievements", { id: record.id });
    } catch (error) {
      notify("Error refreshing achievement data", "error");
    }
  };

  const handleAddClass = async () => {
    if (!selectedClassId) {
      return;
    }

    try {
      await dataProvider.achievementAddStudioClass("achievements", {
        id: record.id,
        studioClassId: selectedClassId,
      });
      notify("Studio class added successfully", "success");
      setSelectedClassId("");
      await refetchAchievement();
    } catch (error) {
      notify("Error adding studio class", "error");
    }
  };

  const handleRemoveClass = async (classId: string) => {
    try {
      await dataProvider.achievementRemoveStudioClass("achievements", {
        id: record.id,
        studioClassId: classId,
      });
      notify("Studio class removed successfully", "success");
      await refetchAchievement();
    } catch (error) {
      notify("Error removing studio class", "error");
    }
  };

  const filterToQuery = (searchText: string): FilterParams => {
    const filter: FilterParams = {};

    // Check if searchText is a valid integer
    const parsedId = parseInt(searchText);
    if (!Number.isNaN(parsedId) && parsedId.toString() === searchText) {
      filter.classId = searchText;
    } else {
      filter.title = searchText;
    }

    return filter;
  };

  return (
    <Box mt={2}>
      <Typography variant="subtitle1" gutterBottom>
        Required Studio Classes
      </Typography>
      <Box display="flex" alignItems="center" mb={2} gridGap={8}>
        <ReferenceInput
          label="Studio Class"
          source="classId"
          reference="classes"
          sort={{ field: "createdAt", order: "DESC" }}
          filterToQuery={filterToQuery}
          style={{ flex: 1, marginRight: 16 }}
        >
          <AutocompleteInput
            optionText={(record: any) =>
              `${record.hidden ? "[HIDDEN]" : ""} ${record.title} (Class ID: ${
                record.classId
              })`
            }
            fullWidth
            onSelect={(record: any) => {
              setSelectedClassId(record.uuid);
            }}
          />
        </ReferenceInput>
        <Button
          label="Add Studio Class"
          onClick={handleAddClass}
          disabled={!selectedClassId}
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
        />
      </Box>
      <Paper>
        <List>
          {record.allRequiredStudioClasses?.map((classItem: any) => (
            <ListItem key={classItem.id}>
              <ListItemText
                style={{ color: classItem.hidden ? "red" : "inherit" }}
                primary={classItem.title}
                secondary={`${classItem.hidden ? "HIDDEN |" : ""} Class ID: ${
                  classItem.classId
                }`}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleRemoveClass(classItem.id)}
                  color="secondary"
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
          {(!record.allRequiredStudioClasses ||
            record.allRequiredStudioClasses.length === 0) && (
            <ListItem>
              <ListItemText primary="No required studio classes" />
            </ListItem>
          )}
        </List>
      </Paper>
    </Box>
  );
};
